
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { useEffect, useState, useRef } from 'react';
import style from './codeVerification.module.css'
import BackIcon from './backIcon'
import consts from './consts';
import 'react-phone-number-input/style.css'
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input'
import utils from './utils'

import {useVerifyCode} from './useVerifyCode';
import { useStateValue } from '../state';

export default function CodeVerification({show, onInit, onBack, onCompletion, onResendClick, senderEmail, 
    containerStyle, lineProgressBarStyle, onGetLink, showSMSVerification, onVerificationFailed})  {
    const EMAIL_VERIFICATION = 'email verification';
    const SMS_VERIFICATION = 'sms verification';
    const SMS_VERIFICATION_ENTER_NUMBER = 'add phone number';

    const [code, updateCode] = useState("");
    const [showProgressBar, updateShowProgressBar] = useState("");
    const [inputFocused, updateInputFocused] = useState(false);
    const [currentViewState, updateCurrentViewState] = useState(EMAIL_VERIFICATION);
    const [senderPhone, updateSenderPhone] = useState("");
    const [senderGeoLocatedCountryCode, updateSenderGeoLocatedCountryCode] = useState(null);

    const codeInputRef = useRef(null);

    const INPUT_PLACE_HOLDER = "Enter verification code";

    const [
        {codeVerificationData, user},
        dispatch,
      ] = useStateValue();

    function onCodeChange(e) {
       updateCode(e.target.value);
    }

    function onVerificationSuccess() {
        updateCode("");
        onCompletion();
    }

    useEffect(() => {
        if (code.length === 6) {
            updateShowProgressBar(true);
            dispatch({ type: 'CODE-VERIFICATION-STATUS-CHANGED', payload: {code: code, status: consts.CODE_VERIFICATION_STATUS.START}});
        } else {
            updateShowProgressBar(false);
        }
    }, [code])

    useEffect(() => {
        if(show) {
            codeInputRef && codeInputRef.current && codeInputRef.current.focus();
            dispatch({ type: 'CODE-VERIFICATION-STATUS-CHANGED', payload: {...codeVerificationData, verificationMethod: consts.CODE_VERIFICATION_METHODS.EMAIL}});
            onInit();
        } else {
            updateCode("");
        }
    }, [show])

    useEffect(() => {
       switch(codeVerificationData.status) {
        case consts.CODE_VERIFICATION_STATUS.START:
            verify();
            break;
           case consts.CODE_VERIFICATION_STATUS.SUCCESSFUL:
            updateShowProgressBar(false);
            onVerificationSuccess();
           case consts.CODE_VERIFICATION_STATUS.FAILED:
           updateShowProgressBar(false);
           onVerificationFailed && onVerificationFailed();
           break;
       }
    }, [codeVerificationData])
 

    useEffect(() => {
        async function getCountryCode() {
            let countryCode = await utils.getUserCountryCode();
            updateSenderGeoLocatedCountryCode(countryCode);
        }
        if(currentViewState === SMS_VERIFICATION_ENTER_NUMBER) {
              getCountryCode();
        }
     }, [currentViewState])

    async function verify() {
        await useVerifyCode(code, dispatch, codeVerificationData, user);
      }

    function getClassByStatus()
    {
        if(!inputFocused) {
            return style.codeInput;
        }
        switch (codeVerificationData.status) {
            case consts.CODE_VERIFICATION_STATUS.IN_PROGRESS:
                return style.codeInputDisabled;
            case consts.CODE_VERIFICATION_STATUS.FAILED:
                return style.codeInputError;    
            default:
                return code != "" ? style.codeInputActive
                 : style.codeInput;
        }
    }

    function handelOnInputBlur() {
        updateInputFocused(false);
    }

    function onGetLinkClick() {
        dispatch({ type: 'ACTIVE-TAB-CHANGED', payload: consts.TAB_NAMES.Generate_Link });
        onGetLink && onGetLink();
    }
    
    function renderLinkIcon() {
        return(
        <svg className={style.linkIcon} width="14" height="14" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.17975 13.936C9.56734 14.3316 10.03 14.6458 10.5405 14.8604C11.0511 15.0749 11.5994 15.1854 12.1532 15.1854C12.707 15.1854 13.2552 15.0749 13.7658 14.8604C14.2764 14.6458 14.739 14.3316 15.1266 13.936L19.8841 9.1785C20.6727 8.38989 21.1157 7.32032 21.1157 6.20507C21.1157 5.08981 20.6727 4.02024 19.8841 3.23164C19.0955 2.44303 18.0259 2 16.9107 2C15.7954 2 14.7258 2.44303 13.9372 3.23164L13.3426 3.82632" stroke="#13BCB4" stroke-width="2.41033" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.936 9.1784C13.5485 8.78282 13.0858 8.46855 12.5753 8.25401C12.0647 8.03946 11.5164 7.92896 10.9626 7.92896C10.4088 7.92896 9.86055 8.03946 9.34998 8.25401C8.83941 8.46855 8.37678 8.78282 7.98919 9.1784L3.2317 13.9359C2.44309 14.7245 2.00006 15.7941 2.00006 16.9093C2.00006 18.0246 2.44309 19.0942 3.2317 19.8828C4.0203 20.6714 5.08987 21.1144 6.20513 21.1144C7.32038 21.1144 8.38996 20.6714 9.17856 19.8828L9.77324 19.2881" stroke="#13BCB4" stroke-width="2.41033" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        );
    }

    function renderSMSIcon() {
        return (
            <svg className={style.smsIcon} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 23 23" fill="none">
            <path d="M2 21.5V5.65626C2 4.68656 2.38521 3.75657 3.07089 3.07089C3.75657 2.38521 4.68656 2 5.65626 2H17.8438C18.8135 2 19.7435 2.38521 20.4291 3.07089C21.1148 3.75657 21.5 4.68656 21.5 5.65626V12.9688C21.5 13.9385 21.1148 14.8685 20.4291 15.5541C19.7435 16.2398 18.8135 16.625 17.8438 16.625H6.87501L2 21.5Z" stroke="#13BCB4" stroke-width="2.11" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.875 6.875H16.625" stroke="#13BCB4" stroke-width="2.11" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.875 11.75H14.1875" stroke="#13BCB4" stroke-width="2.11" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        );
    }

    function renderGetLink() {
        return (
            onGetLink && 
            <div className={style.getLink}>
            {renderLinkIcon()}
            <span onClick={onGetLinkClick} className={style.sendEmailAgain}>{"Get link instead"}</span>
            </div>
        );
    }

    function renderGetCodeViaSMS() {
        return (
            <div className={style.getCodeViaSMS}>
            {renderSMSIcon()}
            <span onClick={() => updateCurrentViewState(SMS_VERIFICATION_ENTER_NUMBER)} className={style.getSMS}>{"Or get code via SMS"}</span>
            </div>
        );
    }

    function onResendSmsClick() {
        updateCurrentViewState(SMS_VERIFICATION_ENTER_NUMBER);
    }

    function renderVerificationView(verificationType) {
        let verificationMethod = verificationType === EMAIL_VERIFICATION ? 'mail' : 'phone';
        return (
            <React.Fragment>
              <img className={style.emailSentImage} src={`/images/${verificationMethod === 'mail' ? 'emailSent.svg' : 'sms.svg'}`} alt="email"></img>
              {false && <BackIcon onClick={onBack} iconStyle={{position: 'relative', left: '4px'}} styleOverride={{position: 'relative', width: '24px', height: '24px', top: '20px', right: '15px', cursor: 'pointer'}}/>}
              <div style={lineProgressBarStyle} className={showProgressBar  ? style.lineProgressBar : style.lineProgressBarHide}/>
              <span className={style.verificationTitle}>{`Check your ${verificationMethod}`}</span>
              <span className={style.verificationText}>We sent a code to <br/> <span style={{fontWeight: '600'}}>{verificationMethod === 'mail' ? senderEmail : senderPhone}</span> <br/> please enter it below:</span>
              {verificationMethod === 'mail' && <span style={{fontSize: '12px'}} className={style.verificationText}>* (Make sure to check your spam <br/> and promotions folders as well)</span>}
              <input type="number" disabled={codeVerificationData.status === consts.CODE_VERIFICATION_STATUS.IN_PROGRESS} ref={codeInputRef} maxLength={6} autoComplete='off' className={getClassByStatus()} inputMode="numeric" pattern="[0-9]*" placeholder={INPUT_PLACE_HOLDER} onChange={onCodeChange} onBlur={handelOnInputBlur} onFocus={() => updateInputFocused(true)} value={code}/>
              <span className={codeVerificationData.status === consts.CODE_VERIFICATION_STATUS.FAILED ? style.errorText : style.errorTextHidden}>Uh-oh that code is <br/> wrong or expired</span>
              <span onClick={() => verificationMethod === 'mail' ? onResendClick() : onResendSmsClick()} className={codeVerificationData.status === consts.CODE_VERIFICATION_STATUS.FAILED ? style.sendEmailAgainAnimatedDown : style.sendEmailAgain}>{codeVerificationData.status === consts.CODE_VERIFICATION_STATUS.FAILED ? 'Send me a new code' : "Resend Code"}</span>
              {verificationMethod === 'mail' ? showSMSVerification && renderGetCodeViaSMS() : renderGetLink()}
            </React.Fragment>
        );
    }

    function sendVerificationCodeToPhone() {
        let eventData = {from_step: SMS_VERIFICATION_ENTER_NUMBER, to_step: SMS_VERIFICATION, user_email: senderEmail, is_work_email: utils.isWorkEmail(senderEmail)};
        utils.trackFormStepChanged(eventData);
        dispatch({ type: 'CODE-VERIFICATION-STATUS-CHANGED', payload: {...codeVerificationData, status: consts.CODE_VERIFICATION_STATUS.CODE_SENT, forceResend: true, verificationMethod: consts.CODE_VERIFICATION_METHODS.SMS, userPhone: senderPhone}});
    }

    function onAddPhoneNumberClick() {
        sendVerificationCodeToPhone();
        updateCurrentViewState(SMS_VERIFICATION);
    }

    function renderAddPhoneView() {
        return (
            <React.Fragment>
              <img className={style.emailSentImage} src={'/images/sms.svg'} alt="email"></img>
              {false && <BackIcon onClick={onBack} iconStyle={{position: 'relative', left: '4px'}} styleOverride={{position: 'relative', width: '24px', height: '24px', top: '20px', right: '15px', cursor: 'pointer'}}/>}
              <div style={lineProgressBarStyle} className={showProgressBar  ? style.lineProgressBar : style.lineProgressBarHide}/>
              <span className={style.verificationTitle}>{'Verify with SMS'}</span>
              <span className={style.verificationText}>Enter your phone number <br/> and we’ll text you a code</span>
              <PhoneInput
                placeholder="Enter phone number"
                value={senderPhone}
                onChange={updateSenderPhone}
                withCountryCallingCode
                defaultCountry={senderGeoLocatedCountryCode || 'CA'}
                international={true}
                countryCallingCodeEditable={false}
                />
              <span onClick={() => onAddPhoneNumberClick()} className={senderPhone && isPossiblePhoneNumber(senderPhone) ? style.continueWithPhone : style.continueWithPhoneDisabled}>{'Continue'}</span>
            </React.Fragment>
        );
    }

      function renderViewByState() {
        switch (currentViewState) {
            case EMAIL_VERIFICATION:
                return renderVerificationView(currentViewState);  
            case SMS_VERIFICATION_ENTER_NUMBER:
                return renderAddPhoneView();  
            case SMS_VERIFICATION:
            return renderVerificationView(currentViewState);       
            default:
            break;
        }
    }

    return (
        <div style={containerStyle} className={show ? style.container : style.containerClosed}>
        {renderViewByState()}
        </div>
    ); 
};