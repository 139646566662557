
/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';

import style from './fileDriverGoogleLogin.module.css'
import consts from './consts'
import utils from './utils'
import restUtils from './restUtils'
import GoogleLogin from 'react-google-login';

import { useStateValue } from '../state';

export default function FileDriverGoogleLogin({buttonText, action, onCompletion, buttonStyle, iconRenderer, textStyle, beforeClick})  {

    const [
        {},
        dispatch,
      ] = useStateValue();

      useEffect(() => {
        async function checkOauthResult() {
          let oauthResult = utils.getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.OAUTH_RESULT);
          if(oauthResult && typeof oauthResult.success !== 'undefined') {
            if(oauthResult.success === 'true') {
              onGoogleSuccess(oauthResult)
            } else {
              onGoogleFailure(oauthResult)
            }
          }
          utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.OAUTH_RESULT, null,7);
        }
    
         window.addEventListener('storage', checkOauthResult)
      
         return () => {
           window.removeEventListener('storage', checkOauthResult)
         }
      }, [])


     async function onGoogleSuccess(response) {
        utils.trackEvent(consts.TRACKING_CATEGORY.LOGIN_OR_SIGNUP, `google_login_${action}`,'success');
        dispatch({ type: 'LOADING-CHANGED', payload: true}); 
        let packageId = utils.getLocalStorageItem(consts.SELECTED_PACKAGE.PACKAGE_ID) || 1; //add default value when coming from landing page and no value is stored in the local storage
        let userInfo = {name: response.userName, email: response.userEmail};
        let res = await restUtils.post('googleSignup', {userInfo: userInfo, signupType: 'google', packageId: packageId}, true);
        if(res.data.isFirstSignup) {
          utils.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.SIGNUP);
          let eventData = {transfer_type: `google-signup`};
          utils.trackWorkQuestionAnsweredEvent(eventData);
        } else {
          let eventData = {transfer_type: `google-signup-existing`};
          utils.trackWorkQuestionAnsweredEvent(eventData);
        }
        dispatch({ type: 'LOADING-CHANGED', payload: false});
        utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.IS_LOGGED_IN, true, 365); //currently we set the session to a year
        utils.setLocalStorageItem(consts.SELECTED_PACKAGE.PAYMENT_SUCCEEDED, null, 7);  //remove PAYMENT_SUCCEEDED as signup is completed and its no longer needed (only used to resume from interrupted payment + signup)
        if(onCompletion) {
            onCompletion(res.data.isFirstSignup);
      } else {  //default behavior
          window.location.assign('/');
      }
    }
  
      function onGoogleFailure(response) {
        utils.trackEvent(consts.TRACKING_CATEGORY.LOGIN_OR_SIGNUP, `google_login_${action}`,'failure', JSON.stringify(response));
        console.log(response.error);
      }
  
      function renderGoogleIcon() {
        return(
          <svg className={style.authIcon} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
          <path d="M4.65403 12.6903L3.92313 15.4191L1.25138 15.4757C0.452811 13.9946 0 12.3002 0 10.4997C0 8.75855 0.42328 7.1167 1.17386 5.6709H1.17427L3.55276 6.10689L4.59455 8.47103C4.37635 9.10677 4.25741 9.78927 4.25741 10.4997C4.25823 11.2703 4.39768 12.009 4.65403 12.6903Z" fill="#FBBB00"/>
          <path d="M20.8162 8.53906C20.9368 9.17439 20.9995 9.83023 20.9995 10.5004C20.9995 11.2522 20.9204 11.9856 20.7698 12.6927C20.2588 15.0995 18.9233 17.2011 17.0731 18.6888L17.0727 18.6883L14.0769 18.5354L13.6528 15.8882C14.8804 15.1684 15.8398 14.0417 16.3455 12.6927H10.7305V8.53906H16.4271H20.8162Z" fill="#518EF8"/>
          <path d="M17.0733 18.6883L17.0737 18.6887C15.2747 20.1353 12.9889 21.0007 10.5005 21.0007C6.50194 21.0007 3.02546 18.7658 1.25195 15.4768L4.6546 12.6914C5.54135 15.058 7.82428 16.7425 10.5005 16.7425C11.651 16.7425 12.7285 16.4316 13.6534 15.8886L17.0733 18.6883Z" fill="#28B446"/>
          <path d="M17.2014 2.41745L13.8 5.2024C12.8431 4.60399 11.7115 4.25864 10.4995 4.25864C7.76255 4.25864 5.43698 6.02066 4.59493 8.47175L1.17424 5.67121H1.17383C2.92109 2.30179 6.44145 0 10.4995 0C13.047 0 15.3832 0.907673 17.2014 2.41745Z" fill="#F14336"/>
          </svg>
        );
      }

    return (
      <button style={buttonStyle} className={style.googleButton}
      onClick={async () => 
          {
            beforeClick && beforeClick();
            var strWindowFeatures = "location=yes,height=778px,width=535px,scrollbars=yes,status=yes";
            let oauthWindow = window.open('', "oauth", strWindowFeatures);
             let res = await restUtils.post('oauth',null, false);
             if(res && res.data) {
              let redirectUrl = res.data;
              oauthWindow.location.assign(redirectUrl);
             // window.open(redirectUrl, "oauth", strWindowFeatures);
             } 
          }}
      disabled={false}> 
        {iconRenderer ? iconRenderer() : renderGoogleIcon()}
        <span style={textStyle} className={style.googleIconText}>{buttonText}</span>
        </button>
      /*  <GoogleLogin
        clientId= {process.env.GOOGLE_CLIENT_ID}
        render={renderProps => (
          <button style={buttonStyle} className={style.googleButton}
          onClick={() => 
              {
                  utils.trackEvent(consts.TRACKING_CATEGORY.LOGIN_OR_SIGNUP, `google_login_${action}`,'button_click');
                  renderProps.onClick();
              }}
          disabled={renderProps.disabled}>
            {renderGoogleIcon()}
            <span className={style.googleIconText}>{buttonText}</span>
            </button>
        )}
        onSuccess={onGoogleSuccess}
        onFailure={onGoogleFailure}
        //cookiePolicy={'http://localhost:3000'} //TODO: Change it to prod when in prod
        cookiePolicy={'single_host_origin'}
        isSignedIn={false}
      />*/
    ); 
}