/* eslint-disable react/react-in-jsx-scope */
export default function BackIcon({styleOverride, onClick, iconStyle})  {
    return (
        <div id='back-icon' onClick={onClick} style={ styleOverride ? styleOverride : {position: "relative", width: "24px", height: "24px", left: "20px", top: "20px", cursor: "pointer"}}>
        <svg style={iconStyle} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M0.5 8.5L15.5 8.5C15.7761 8.5 16 8.27614 16 8C16 7.72386 15.7761 7.5 15.5 7.5L0.5 7.5C0.223858 7.5 0 7.72386 0 8C0 8.27614 0.223858 8.5 0.5 8.5Z" fill="#676879"/>
        <path d="M7.23197 1.8501C7.42533 1.65296 7.42225 1.33639 7.2251 1.14303C7.02796 0.949674 6.71139 0.952747 6.51803 1.1499L0.143031 7.6499C-0.0476772 7.84434 -0.0476772 8.15566 0.143031 8.3501L6.51803 14.8501C6.71139 15.0473 7.02796 15.0503 7.2251 14.857C7.42225 14.6636 7.42533 14.347 7.23197 14.1499L1.20034 8L7.23197 1.8501Z" fill="#676879"/>
        </svg>
        </div>
    );
};
