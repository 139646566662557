import React from 'react'
import utils from './utils'
import consts from './consts'
import restUtils from './restUtils'

async function useVerifyCode(code, dispatch, codeVerificationData, user){
  
  const getUserId = utils.userId();
  const userId = getUserId();
  const sessionId = utils.getSessionId();
  if (userId != '') {
      let verificationDTO = {userId: userId, sessionId: sessionId, code: code}
      dispatch({ type: 'CODE-VERIFICATION-STATUS-CHANGED', payload: {...codeVerificationData, status:consts.CODE_VERIFICATION_STATUS.IN_PROGRESS}});
      let res = await restUtils.post('verifyCode', verificationDTO, true);
      let success = res.data.isVerified;
      let registeredUserId = res.data.userId;
      let packageId = res.data.packageId;
      utils.trackEvent(consts.TRACKING_CATEGORY.LOGIN_OR_SIGNUP, 'verification_succeeded', success, registeredUserId || user.userId);
      registeredUserId && dispatch({ type: 'USER-CHANGED', payload: {...user, userId: registeredUserId, packageId: packageId}});
      dispatch({ type: 'CODE-VERIFICATION-STATUS-CHANGED', payload: {...codeVerificationData, status: success ? consts.CODE_VERIFICATION_STATUS.SUCCESSFUL : consts.CODE_VERIFICATION_STATUS.FAILED}});
      return success;
  }
  return false;
}

export {useVerifyCode}
