
/* eslint-disable react/react-in-jsx-scope */
import style from './loaderOverlay.module.css'

export default function LoaderOverlay({LoaderOverlayStyle, children})  {
  return (
    <div style={LoaderOverlayStyle} className={style.loaderContainer}>
    <div className={style.loader}></div>
      {children}
  </div>
  )}