
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import {useState, useEffect} from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import style from './loginForm.module.css'
import consts from './consts'
import CodeVerification from './codeVerification'
import utils from './utils'
import restUtils from './restUtils'
import FileDriverGoogleLogin from './fileDriverGoogleLogin'

import { useStateValue } from '../state';
import config from './config'

export default function LoginForm({actionType, questionAnswer, onSuccess, onError, buttonText,
    onCompletion, showSMSVerification, children, formStyle, verificationStyle,
    verificationLineProgressBarStyle, inputStyle, signupFormStyle, onEmailSignupClick})  {
    const [showCodeVerification, updateShowCodeVerification] = useState(false);
    const [forceCodeResend, updateForceCodeResend] = useState(true);
    const [loginEmail, updateLoginEmail] = useState("");
    const [userName, updateUserName] = useState("");
    const [actionStatus, updateActionStatus] = useState("");
    const [isButtonEnabled, updateIsButtonEnabled] = useState(false);
    const [signupWithEmail, updateSignupWithEmail] = useState(false);
    const [acceptedTerms, updateAcceptedTerms] = useState(false);
    const [verificationFailed, updateVerificationFailed] = useState(false);

    const router = useRouter();

    const ACTION_STATUS_SUCCEEDED = 'action succeeded';
    const ACTION_STATUS_FAILED = 'action failed';
    const ACTION_STATUS_IN_PROGRESS = 'action in progress';

    const CODE_VERIFICATION = 'code verification';
    const SIGNUP_HOMEPAGE = 'signup-homepage';

    const [
        {codeVerificationData, user},
        dispatch,
      ] = useStateValue();

    async function handleOnClick() {
        let res;
         switch (actionType) {
             case 'login':
                 updateActionStatus(ACTION_STATUS_IN_PROGRESS);
                 res = await restUtils.post('login', {userEmail:loginEmail}, true);
                 utils.trackEvent(consts.TRACKING_CATEGORY.LOGIN_OR_SIGNUP, 'login_click','email_address',loginEmail);
                 break;
             case 'signup':
                updateActionStatus(ACTION_STATUS_IN_PROGRESS);
                 let packageId = utils.getLocalStorageItem(consts.SELECTED_PACKAGE.PACKAGE_ID) || config.DEFAULT_PACKAGE_ID;
                 //let isPaying = utils.getLocalStorageItem(consts.SELECTED_PACKAGE.PaidPackage);
                 res = await restUtils.post('signup', {userEmail:loginEmail, userName: userName, signupType: 'email', packageId:  packageId}, true);
                 utils.trackEvent(consts.TRACKING_CATEGORY.LOGIN_OR_SIGNUP, 'signup_click','email_address',loginEmail);
                 break;
             default:
                 break;
         }
         let actionStatus = res.data ? ACTION_STATUS_SUCCEEDED : ACTION_STATUS_FAILED;
         updateActionStatus(actionStatus);
         utils.trackEvent(consts.TRACKING_CATEGORY.LOGIN_OR_SIGNUP, 'action_completed',actionType, actionStatus === ACTION_STATUS_SUCCEEDED ? 'success' : 'failure');
    } 

    function onVerificationSuccess() {
        if(actionType === 'signup') { 
            utils.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.SIGNUP);
            let packageId = utils.getLocalStorageItem(consts.SELECTED_PACKAGE.PACKAGE_ID);
            let eventData = {transfer_type: `${packageId === 1 ? 'personal' : packageId === 2 ? 'work' : 'unknown'}-signup`};
            utils.trackWorkQuestionAnsweredEvent(eventData);
        }
        let verifiedEventData = {from_step: `${actionType}-${CODE_VERIFICATION}`, to_step: SIGNUP_HOMEPAGE, user_email: loginEmail, is_work_email: utils.isWorkEmail(loginEmail)};
        utils.trackFormStepChanged(verifiedEventData);
        utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.IS_LOGGED_IN, true, 365); //currently we set the session to a year
        utils.setLocalStorageItem(consts.SELECTED_PACKAGE.PAYMENT_SUCCEEDED, null, 7);  //remove PAYMENT_SUCCEEDED as signup is completed and its no longer needed (only used to resume from interrupted payment + signup)
        if(onCompletion) {
            let isFirstSignup = actionType === 'signup';
            onCompletion(isFirstSignup); //override default completion behavior
        } else { //default behavior
            let shouldJumpToEditor = utils.getSessionStorageItem(consts.SESSION_STORAGE_KEYS.JUMP_TO_EDITOR)
            if(shouldJumpToEditor & user.userId) {
                router.push(`/posts/brand-editor?userId=${user.userId}`);
            } else {
                 router.push('/');
            } 
        }
    }

    function onVerificationFailed() {
        updateVerificationFailed(true);
    }

    function onCodeVerificationInit() {
        let verifiedEventData = {from_step: actionType, to_step: `${actionType}-${CODE_VERIFICATION}`, user_email: loginEmail, is_work_email: utils.isWorkEmail(loginEmail)};
        utils.trackFormStepChanged(verifiedEventData);
        dispatch({ type: 'CODE-VERIFICATION-STATUS-CHANGED', payload: {...codeVerificationData, status: consts.CODE_VERIFICATION_STATUS.CODE_SENT, verificationMethod: consts.CODE_VERIFICATION_METHODS.EMAIL, forceResend: forceCodeResend}});
    }

    function onResendEmailClick() {
        updateForceCodeResend(true);
        updateActionStatus("");
        updateShowCodeVerification(false);
    }

    useEffect(() => {
        switch(codeVerificationData.status) {
            case consts.CODE_VERIFICATION_STATUS.CODE_SENT:
            utils.sendVerificationCode(loginEmail, forceCodeResend, codeVerificationData.verificationMethod, codeVerificationData.userPhone);
             break;
        }
     }, [codeVerificationData])

     useEffect(() => {
         switch (actionStatus) {
             case ACTION_STATUS_SUCCEEDED:
                updateShowCodeVerification(true);
                onSuccess && onSuccess();
                 break;
            case ACTION_STATUS_FAILED:
               updateShowCodeVerification(false);
               onError && onError();
                break;
             default:
                break;
         }
     }, [actionStatus])

     useEffect(() => {
        if(actionType === 'signup') {
            setButtonEnabledByCondition(utils.isEmail(loginEmail) && userName != '' && acceptedTerms);
        }
        if(actionType === 'login') {
            setButtonEnabledByCondition(utils.isEmail(loginEmail));
        }
     }, [loginEmail, userName, acceptedTerms])

     useEffect(() => {
        if(actionType === 'signup') { //auto fill the user's email if we can
           let userEmailFromCta = router.query["email"];
           if(userEmailFromCta) {
            updateLoginEmail(userEmailFromCta);
           } else {
            let userEmailFromForm = utils.getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_FROM);
            if(userEmailFromForm) {
                updateLoginEmail(userEmailFromForm);
            }
           }
        }
     }, [router])

     function setButtonEnabledByCondition(enableCondition) {
        if(enableCondition) {
            updateIsButtonEnabled(true);
        } else {
            updateIsButtonEnabled(false);
        }
     }

     function shouldShowError(errorType) {
        return actionStatus === ACTION_STATUS_FAILED && actionType === errorType && 'block'
     }

    function renderLoginForm() {
        return (
            <div id='EmailLogin' style={{display: showCodeVerification && 'none', paddingTop: '30px'}}>
        <FileDriverGoogleLogin buttonText={'Log in with Google'} onCompletion={onCompletion} action={'login'}/>
        <div className={style.separatorContainer}>
        <div className={style.separator}/>
        <span className={style.separatorText}>Or</span>
        </div>
        <div className={style.emailLoginContainer}>
        <input style={inputStyle} className={style.emailLoginInput} placeholder={'Your email'} value = {loginEmail} onChange={e => updateLoginEmail(e.target.value)}></input>
        <button disabled={!isButtonEnabled || actionStatus === ACTION_STATUS_IN_PROGRESS} onClick={handleOnClick} className={isButtonEnabled ? style.emailLoginButton : style.emailLoginButtonDisabled} tabIndex={0}>
        {actionStatus === ACTION_STATUS_IN_PROGRESS && <img className={style.spinner} src="/images/spinner.gif" alt=""/>}
                {'Continue'}
            </button>
        </div>
       </div>
        );
    }  

    function renderEmptyCheckbox(onCheckboxClick){
        return(
            <svg tabIndex={0} style={{cursor: 'pointer', outline: 'none'}} onClick={onCheckboxClick} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <rect x="0.5" y="0.5" width="14" height="14" rx="0.7" stroke="#C4C4C4"/>
            </svg>
        );
      }
  
      function renderSelectedCheckbox(onSelectedCheckboxClick){
        return(
            <svg style={{cursor: 'pointer', outline: 'none'}} onClick={onSelectedCheckboxClick} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path d="M13.3333 0H1.66667C0.741667 0 0 0.75 0 1.66667V13.3333C0 14.25 0.741667 15 1.66667 15H13.3333C14.2583 15 15 14.25 15 13.3333V1.66667C15 0.75 14.2583 0 13.3333 0ZM5.83333 11.6667L1.66667 7.5L2.84167 6.325L5.83333 9.30833L12.1583 2.98333L13.3333 4.16667L5.83333 11.6667Z" fill="#13BCB4"/>
            </svg>
        );
      }

    function renderAcceptTerms() {
        return (
          <div className={style.checkboxAnswersItemsContainer}>
           <div className={style.answerItem}>
                {acceptedTerms ? renderSelectedCheckbox(() => updateAcceptedTerms(false)) : renderEmptyCheckbox(() => updateAcceptedTerms(true))}
                <span className={style.answerText}>I accept the <Link href='/posts/terms'><a target="_blank" rel="noopener noreferrer" className={style.termsLink}>terms and conditions</a></Link></span>
              </div>
          </div>
        );
}

    function renderSignupForm() {
        return (
            <div className={style.signupForm} id='EmailSignUp' style={{...signupFormStyle,...{display: showCodeVerification && 'none', paddingTop: signupFormStyle ? signupFormStyle.paddingTop : '30px'}}}>
        <FileDriverGoogleLogin buttonText={'Sign in with Google'} onCompletion={onCompletion} action={'signup'}/>
        {!signupWithEmail && <span onClick={() => {
            onEmailSignupClick && onEmailSignupClick();
            updateSignupWithEmail(true);
            }} className={style.signUpWithEmail}>
        Or sign up with email
        </span>}
       {signupWithEmail &&
       <React.Fragment>
        <input style={inputStyle} className={style.input} placeholder={'Your name'} value = {userName} onChange={e => updateUserName(e.target.value)}/>
            <input style={inputStyle} className={style.emailInput} placeholder={'Your email'} value = {loginEmail} onChange={e => updateLoginEmail(e.target.value)}></input>
            {renderAcceptTerms()}
            {children}
            <button disabled={!isButtonEnabled || actionStatus === ACTION_STATUS_IN_PROGRESS} onClick={handleOnClick} className={isButtonEnabled ? style.loginButton : style.loginButtonDisabled} tabIndex={0}>
               {actionStatus === ACTION_STATUS_IN_PROGRESS && <img className={style.spinner} src="/images/spinner.gif" alt=""/>}
                {buttonText || 'Continue With Email'}
            </button>
            <span style={{display: shouldShowError('signup')}} className={style.errorText}>This email is already <br/> Registered, try to <a href='/posts/login' className={style.loginLink}>login</a></span>
       </React.Fragment>}
            </div>
        );
    }

    function getContainerClassName() {
        switch (actionType) {
            case 'login':
            if(actionStatus === ACTION_STATUS_SUCCEEDED) {
                return style.containerLogin
            }
            return style.containerLoginSmall;
            case 'signup':
            if(actionStatus === ACTION_STATUS_FAILED) {
              return style.containerSignupError;
            }
            return style.containerSignup;
            default:
            break;
        }
    }

    return (
        <div style={{...formStyle, height: showCodeVerification && verificationFailed ? '385px' :  signupWithEmail ? '335px' : formStyle && formStyle.height}} className={getContainerClassName()}>
            {actionType === 'signup' ? renderSignupForm() : renderLoginForm()}
            <CodeVerification containerStyle={verificationStyle ?  verificationStyle : {paddingTop: '20px'}} show={showCodeVerification} onInit={onCodeVerificationInit} 
                 onCompletion={onVerificationSuccess} onVerificationFailed={onVerificationFailed} onResendClick={onResendEmailClick} senderEmail={loginEmail} showSMSVerification={showSMSVerification}
                 lineProgressBarStyle={verificationLineProgressBarStyle}/>
        </div>
    ); 
}